import DateParser from "@/ValueObjects/Shared/MyDate";

export default class DomainEvent {
  private readonly _id: string;
  private readonly _previousEventId: string;
  private readonly _aggregateId: string;
  private readonly _occurredAt: Date;
  private readonly _eventName: string;

  constructor(
    id: string,
    previousEventId: string,
    aggregateId: string,
    occurredAt: Date,
    eventName: string
  ) {
    this._id = id;
    this._previousEventId = previousEventId;
    this._aggregateId = aggregateId;
    this._occurredAt = occurredAt;
    this._eventName = eventName;
  }

  static fromResponse(data: any): DomainEvent {
    return new DomainEvent(
      data.id.value,
      data.previousEventId,
      data.aggregateId,
      DateParser.parse(data.occurredAt),
      data.eventName
    );
  }

  get id(): string {
    return this._id;
  }

  get previousEventId(): string {
    return this._previousEventId;
  }

  get aggregateId(): string {
    return this._aggregateId;
  }

  get occurredAt(): Date {
    return this._occurredAt;
  }

  get eventName(): string {
    return this._eventName;
  }
}
