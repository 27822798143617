










































import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";
import Contract from "@/ValueObjects/Contract/Contract";
import DatePickerInput from "@/components/DatePickerInput.vue";

@Component({
  components: { DatePickerInput },
  props: {
    contract: {
      type: Contract,
      required: true
    },
    vOn: {
      type: Object
    },
    vBtnProps: {
      type: Object
    },
    vIconProps: {
      type: Object
    }
  }
})
export default class AddPaymentPlanButton extends Vue {
  isDialogOpen: boolean = false;
  isSendingRequest: boolean = false;
  paymentPlans: string[] = [
    "monthly",
    "quarterly",
    "semi-annually",
    "annually"
  ];
  formRules = {
    paymentPlan: [(v: any) => !!v || "Payment plan is required"],
    amount: [
      (v: any) => !!v || "Payment plan amount is required",
      (v: number) => v > 0 || "Payment plan amount must be greater than 0"
    ]
  };
  paymentPlan: string = this.paymentPlans[0];
  amount: number | null = null;
  begin: Date = new Date();

  public doRequest(): void {
    if (this.isSendingRequest) {
      return;
    }

    this.isSendingRequest = true;

    this.$http
      .post(
        `/api/contract/add-payment-plan`,
        {
          contractId: this.$props.contract.id.id,
          paymentPlan: this.paymentPlan,
          amount: Number(this.amount),
          begin: {
            date: DatePickerInput.convertDateToISO8601(this.begin)
          }
        },
        {
          headers: {
            Authorization: "Bearer " + Authenticator.getInstance().token
          }
        }
      )
      .then(() => {
        this.$emit("paymentPlanAdded");
        this.isDialogOpen = false;
      })
      .finally(() => {
        this.isSendingRequest = false;
      });
  }
}
