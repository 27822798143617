var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-15"},[_c('v-checkbox',{attrs:{"label":"Load all contracts"},on:{"click":_vm.loadContracts},model:{value:(_vm.shouldLoadAllContracts),callback:function ($$v) {_vm.shouldLoadAllContracts=$$v},expression:"shouldLoadAllContracts"}}),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line@4","loading":_vm.isLoading}}):_vm._l((_vm.contracts),function(contract){return _c('v-expansion-panels',{key:contract.id.id,staticClass:"mb-4 contract-panel",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"contract-panel-header d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(contract.title)+" "),_c('div',{staticClass:"pt-2 grey--text"},[_vm._v(" ("+_vm._s(_vm._f("date")(contract.period.begin))+" - "),(contract.period.end !== null)?[_vm._v(" "+_vm._s(_vm._f("date")(contract.period.end))+") ")]:[_vm._v("now)")]],2)]),_c('div',{staticClass:"flex-grow-0 mr-5",staticStyle:{"white-space":"nowrap"}},[_c('v-btn-toggle',{staticClass:"mr-3"},[_c('AddPaymentPlanButton',{attrs:{"contract":contract,"v-btn-props":{
                small: true,
                outlined: true
              },"v-icon-props":{
                small: true
              }},on:{"paymentPlanAdded":_vm.loadContracts}})],1),_c('v-btn-toggle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outlined":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.contractDetails(contract)}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}},{key:"default",fn:function(){return [_vm._v("Show details")]},proxy:true}],null,true)}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var tooltip = ref.on;
return [_c('CancelContractButton',{attrs:{"contract":contract,"v-btn-props":{
                    small: true,
                    outlined: true,
                    color: 'red darken-4'
                  },"v-icon-props":{
                    small: true,
                    color: 'red darken-4'
                  }},on:{"contractCancelled":_vm.loadContracts}})]}},{key:"default",fn:function(){return [_vm._v("Cancel contract")]},proxy:true}],null,true)})],1)],1)]),_c('v-expansion-panel-content',{staticClass:"contract-panel-content"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Effective since/from")]),_c('th',[_vm._v("Interval")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Action")])])]),_c('tbody',_vm._l((contract.paymentPlans),function(paymentPlan,index){return _c('tr',{key:("contract-paymentPlan-" + index)},[_c('td',[_vm._v(_vm._s(_vm._f("date")(paymentPlan.effectiveFrom)))]),_c('td',[_vm._v(_vm._s(paymentPlan.plan))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(paymentPlan.amount)))]),_c('td',[_c('v-btn',{attrs:{"small":"","outlined":"","disabled":"","color":"red darken-4"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)])}),0)]},proxy:true}],null,true)})],1)],1)],1)}),_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var dialog = ref.on;
return [_c('v-fab-transition',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fixed":"","bottom":"","right":"","fab":"","color":"primary"}},Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_vm._v(" New contract ")])],1)]}}]),model:{value:(_vm.isCreateContractDialogOpen),callback:function ($$v) {_vm.isCreateContractDialogOpen=$$v},expression:"isCreateContractDialogOpen"}},[_c('CreateContract',{on:{"contactCreated":_vm.contactCreated,"createContractAborted":_vm.createContractAborted}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }