












































































import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";
import { default as ContractVO } from "@/ValueObjects/Contract/Contract";
import DeleteContractButton from "@/components/Contract/DeleteContractButton.vue";

@Component({
  components: { DeleteContractButton },
  props: {
    contractId: {
      type: String,
      required: true
    }
  }
})
export default class Contract extends Vue {
  contract: ContractVO | null = null;
  isLoading: boolean = false;
  isDeleting: boolean = false;

  constructor() {
    super();

    this.load();
  }

  public contractDeleted(): void {
    this.$router.push({
      name: "contracts"
    });
  }

  private load() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    let token: string | null = Authenticator.getInstance().token;

    this.$http
      .get(`/api/contract/${this.$props.contractId}`, {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token
        }
      })
      .then(({ data }: { data: any }) => {
        this.contract = ContractVO.fromResponse(data);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
