import ContractId from "@/ValueObjects/Contract/ContractId";
import ContractPeriod from "@/ValueObjects/Contract/ContracPeriod";
import PaymentPlan from "@/ValueObjects/Contract/PaymentPlan";
import DomainEvent from "@/ValueObjects/Shared/DomainEvent";
import Payment from "@/ValueObjects/Payment/Payment";

export default class Contract {
  private readonly _id: ContractId;
  private readonly _title: string;
  private readonly _period: ContractPeriod;
  private readonly _paymentPlans: ReadonlyArray<PaymentPlan>;
  private readonly _payments: Payment[];
  private readonly _events: DomainEvent[];

  constructor(
    id: ContractId,
    title: string,
    period: ContractPeriod,
    paymentPlans: PaymentPlan[],
    payments: Payment[],
    events: DomainEvent[]
  ) {
    this._id = id;
    this._title = title;
    this._period = period;
    this._paymentPlans = paymentPlans;
    this._payments = payments;
    this._events = events;
  }

  static fromResponse(data: any): Contract {
    return new Contract(
      new ContractId(data.id),
      data.title,
      ContractPeriod.fromResponse({ begin: data.begin, end: data.end }),
      data.paymentPlans.map((data: any) => {
        return PaymentPlan.fromResponse(data);
      }),
      data.payments.map((data: any) => {
        return Payment.fromResponse(data);
      }),
      data.history.map((data: any) => {
        return DomainEvent.fromResponse(data);
      })
    );
  }

  get id(): ContractId {
    return this._id;
  }

  get title(): string {
    return this._title;
  }

  get period(): ContractPeriod {
    return this._period;
  }

  get paymentPlans(): ReadonlyArray<PaymentPlan> {
    return this._paymentPlans;
  }

  get payments(): Payment[] {
    return this._payments;
  }

  get events(): DomainEvent[] {
    return this._events;
  }
}
