















































import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";

@Component
export default class Backup extends Vue {
  isUploading: boolean = false;
  isDownloading: boolean = false;
  truncateData: boolean = false;
  file: any = null;

  public doImport(): void {
    if (this.isUploading) {
      return;
    }

    this.isUploading = true;

    const data = new FormData();
    data.append("file", this.file);
    if (this.truncateData) {
      data.append("truncate", "true");
    }

    this.$http
      .post(`/api/import`, data, {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token,
          "Content-Type": "multipart/form-data"
        }
      })
      .finally(() => {
        this.isUploading = false;
      });
  }

  public doExport(): void {
    if (this.isDownloading) {
      return;
    }

    this.isDownloading = true;

    this.$http
      .get(`/api/export`, {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token
        },
        responseType: "blob"
      })
      .then(response => {
        const blob = new Blob([response.data], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.fileName(response, "export.json");
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .finally(() => {
        this.isDownloading = false;
      });
  }

  private fileName(response: any, fallback: string): string {
    const contentDisposition = response.headers["content-disposition"];

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

      if (fileNameMatch.length === 2) {
        return fileNameMatch[1];
      }
    }

    return fallback;
  }
}
