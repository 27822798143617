

























































import Vue from "vue";
import Component from "vue-class-component";
import DatePickerInput from "@/components/DatePickerInput.vue";
import Authenticator from "@/api/Authenticator";

@Component({
  components: {
    DatePickerInput
  }
})
export default class CreateContract extends Vue {
  isCreatingContract: boolean = false;
  isCreateContractFormValid: boolean = false;
  createContractFormRules = {
    title: [(v: any) => !!v || "Title is required"],
    begin: [(v: any) => !(v instanceof Date) || "Begin is required"],
    paymentPlan: [(v: any) => !!v || "Payment plan is required"],
    paymentPlanAmount: [
      (v: any) => !!v || "Payment plan amount is required",
      (v: number) => v > 0 || "Payment plan amount must be greater than 0"
    ],
    paymentPlanEffectiveFrom: [
      (v: any) => !(v instanceof Date) || "Effective from is required"
    ]
  };
  paymentPlans: string[] = [
    "monthly",
    "quarterly",
    "semi-annually",
    "annually"
  ];
  title: string = "";
  begin: Date = new Date();
  end: Date | null = null;
  paymentPlan: string = this.paymentPlans[0];
  paymentPlanAmount: number | null = null;
  paymentPlanEffectiveFrom: Date = new Date();

  public createContract(): void {
    if (!this.isCreateContractFormValid || this.isCreatingContract) {
      return;
    }

    this.isCreatingContract = true;

    this.$http
      .post(
        "/api/contract",
        {
          title: this.title,
          begin: {
            date: DatePickerInput.convertDateToISO8601OrNull(this.begin)
          },
          end: this.end
            ? { date: DatePickerInput.convertDateToISO8601OrNull(this.end) }
            : null,
          paymentPlan: this.paymentPlan,
          paymentPlanAmount: Number(this.paymentPlanAmount),
          paymentPlanEffectiveFrom: {
            date: DatePickerInput.convertDateToISO8601OrNull(
              this.paymentPlanEffectiveFrom
            )
          }
        },
        {
          headers: {
            Authorization: "Bearer " + Authenticator.getInstance().token
          }
        }
      )
      .then(() => {
        this.resetContractFormData();
        this.$emit("contractCreated");
      })
      .finally(() => {
        this.isCreatingContract = false;
      });
  }

  public createContractAborted(): void {
    this.$emit("createContractAborted");
  }

  private resetContractFormData(): void {
    this.title = "";
    this.begin = new Date();
    this.end = null;
    this.paymentPlan = this.paymentPlans[0];
    this.paymentPlanAmount = null;
    this.paymentPlanEffectiveFrom = new Date();
  }
}
