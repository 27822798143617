export default class Month {
  private readonly _number: number;

  constructor(number: number) {
    if (number < 1 || number > 12) {
      throw new Error("Invalid argument");
    }

    this._number = number;
  }

  get number() {
    return this._number;
  }

  get value() {
    switch (this.number) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "!INVALID!";
    }
  }
}
