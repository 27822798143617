


























import Component from "vue-class-component";
import Vue from "vue";

@Component({
  props: {
    label: {
      type: String
    },
    value: {
      validator: prop => {
        return prop instanceof Date || prop === null;
      },
      default: null
    },
    required: {
      type: Boolean
    }
  }
})
export default class DatePickerInput extends Vue {
  isDialogOpen: boolean = false;
  dateString: string | null = null;

  mounted() {
    this.dateString = this.convertDate(this.$props.value);
    this.$watch(
      () => this.$props.value,
      (value: Date | null) => {
        this.dateString = this.convertDate(value);
      }
    );
  }

  public choose(dateString: string | null): void {
    this.isDialogOpen = false;
    this.$emit(
      "input",
      dateString !== null ? new Date(Date.parse(dateString)) : null
    );
  }

  public cancel(): void {
    this.isDialogOpen = false;
    this.dateString = this.convertDate(this.$props.value);
  }

  public textFieldChanged(value: string | null): void {
    if (value === null) {
      this.choose(null);
    }
  }

  public convertDate(date: Date | null): string | null {
    return DatePickerInput.convertDateToISO8601OrNull(date);
  }

  public static convertDateToISO8601OrNull(date: Date | null): string | null {
    if (date === null) {
      return null;
    }

    return DatePickerInput.convertDateToISO8601(date);
  }

  public static convertDateToISO8601(date: Date): string {
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0")
    );
  }
}
