export default (value: Date | null) => {
  if (value === null) {
    return "";
  }

  return value.toLocaleDateString(["en-US", "de-DE"], {
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
};
