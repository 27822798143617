import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import Authenticator from "@/api/Authenticator";
import ContractList from "@/views/ContractList.vue";
import Contract from "@/views/Contract.vue";
import Report from "@/views/Report.vue";
import Backup from "@/views/Backup.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "home", component: Home },
  { path: "/contracts", name: "contracts", component: ContractList },
  {
    path: "/contract/:contractId",
    name: "contract",
    component: Contract,
    props: true
  },
  { path: "/report", name: "report", component: Report },
  { path: "/backup", name: "backup", component: Backup },
  { path: "/login", name: "login", component: Login },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (Authenticator.getInstance().isLoggedIn() && to.name === "login") {
    next(Authenticator.getInstance().redirectUrl);
    return;
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !Authenticator.getInstance().isLoggedIn()) {
    // @ts-ignore
    Authenticator.getInstance().setRedirectUrl(to.path);
    next("/login");
    return;
  }

  next();
});

export default router;
