












































































































































import Vue from "vue";
import Contract from "@/ValueObjects/Contract/Contract";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";
import CreateContract from "@/components/Contract/CreateContract.vue";
import DeleteContractButton from "@/components/Contract/DeleteContractButton.vue";
import CancelContractButton from "@/components/Contract/CancelContractButton.vue";
import AddPaymentPlanButton from "@/components/Contract/AddPaymentPlanButton.vue";

@Component({
  components: {
    AddPaymentPlanButton,
    CancelContractButton,
    DeleteContractButton,
    CreateContract
  }
})
export default class ContractList extends Vue {
  contracts: Contract[] = [];
  isLoading: boolean = false;
  isCreateContractDialogOpen: boolean = false;
  shouldLoadAllContracts: boolean = false;

  constructor() {
    super();

    this.loadContracts();
  }

  public createContractAborted(): void {
    this.isCreateContractDialogOpen = false;
  }

  public contactCreated(): void {
    this.isCreateContractDialogOpen = false;
    this.loadContracts();
  }

  public contractDetails(contract: Contract): void {
    this.$router.push({
      name: "contract",
      params: { contractId: contract.id.id }
    });
  }

  private loadContracts() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    let token: string | null = Authenticator.getInstance().token;

    const params: any = {};
    if (!this.shouldLoadAllContracts) {
      params.filterContractsEndedPriorCurrentYear = true;
    }

    this.$http
      .get("/api/contract", {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token
        },
        params: params
      })
      .then(({ data }: { data: [] }) => {
        this.contracts = data.map(contractData => {
          return Contract.fromResponse(contractData);
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
