import Money from "@/ValueObjects/Shared/Money";
import DateParser from "@/ValueObjects/Shared/MyDate";

export default class Payment {
  private readonly _id: string;
  private readonly _title: string;
  private readonly _contractId: string | null;
  private readonly _amount: Money;
  private readonly _dueDate: Date;

  constructor(
    id: string,
    title: string,
    contractId: string | null,
    amount: Money,
    dueDate: Date
  ) {
    this._id = id;
    this._title = title;
    this._contractId = contractId;
    this._amount = amount;
    this._dueDate = dueDate;
  }

  static fromResponse(data: any): Payment {
    return new Payment(
      data.id,
      data.title,
      data.contractId,
      Money.fromResponse(data.amount),
      DateParser.parse(data.dueDate)
    );
  }

  get id(): string {
    return this._id;
  }

  get title(): string {
    return this._title;
  }

  get contractId(): string | null {
    return this._contractId;
  }

  get amount(): Money {
    return this._amount;
  }

  get dueDate(): Date {
    return this._dueDate;
  }
}
