














































import Vue from "vue";
import Component from "vue-class-component";
import Authenticator from "@/api/Authenticator";

@Component
export default class Login extends Vue {
  email: string = "";
  password: string = "";
  isLoggingIn: boolean = false;

  login(): void {
    if (this.isLoggingIn) {
      return;
    }

    this.isLoggingIn = true;

    Authenticator.getInstance()
      .login(this.email, this.password)
      .then(() => {
        this.$router.push(Authenticator.getInstance().redirectUrl);
      })
      .catch(error => {
        // this.showSnackbar("Wrong login credentials.");
      })
      .finally(() => {
        this.isLoggingIn = false;
      });
  }
}
