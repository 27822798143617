import Payment from "@/ValueObjects/Payment/Payment";
import Month from "@/ValueObjects/Shared/Month";
import Money from "@/ValueObjects/Shared/Money";

export default class MonthReport {
  private readonly _month: Month;
  private readonly _payments: Payment[];
  private readonly _totalAmount: Money;

  constructor(month: Month, payments: Payment[]) {
    this._month = month;
    this._payments = payments;
    this._totalAmount = payments.reduce((amount: Money, current: Payment) => {
      return amount.add(current.amount);
    }, Money.EUR(0));
  }

  static fromResponse(data: any): MonthReport {
    return new MonthReport(
      new Month(data.month),
      data.payments.map((paymentData: any) => {
        paymentData.amount = paymentData.payment;
        return Payment.fromResponse(paymentData);
      })
    );
  }

  get month(): Month {
    return this._month;
  }

  get payments(): Payment[] {
    return this._payments;
  }

  get totalAmount(): Money {
    return this._totalAmount;
  }
}
