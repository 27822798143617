









































import Vue from "vue";
import Payment from "@/ValueObjects/Payment/Payment";
import Authenticator from "@/api/Authenticator";
import Money from "@/ValueObjects/Shared/Money";
import Component from "vue-class-component";

@Component
export default class Home extends Vue {
  payments: Payment[] = [];
  isLoading: boolean = false;

  constructor() {
    super();

    this.load();
  }

  load(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.$http
      .get("/api/report/upcoming-payments", {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token
        }
      })
      .then(({ data }: { data: [] }) => {
        this.payments = data.map(data => {
          return Payment.fromResponse(data);
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  recordPayment(payment: Payment): void {
    this.$http
      .post(
        "/api/contract/record-payment",
        {
          contractId: payment.contractId,
          amount: payment.amount.amount
        },
        {
          headers: {
            Authorization: "Bearer " + Authenticator.getInstance().token
          }
        }
      )
      .then(() => {
        this.load();
      });
  }

  get total(): Money {
    return this.payments.reduce(
      (total, payment) => total.add(payment.amount),
      Money.EUR(0)
    );
  }
}
