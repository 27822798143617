import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import DateFilter from "@/filters/dateFilter";
import CurrencyFilter from "@/filters/currencyFilter";

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost";
} else {
  axios.defaults.baseURL =
    "https://" +
    window.location.hostname.replace(
      "fima2.hergerdt.de",
      "api.fima2.hergerdt.de"
    );
}

Vue.use(VueAxios, axios);
Vue.filter("date", DateFilter);
Vue.filter("currency", CurrencyFilter);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
