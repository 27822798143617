













import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  constructor() {
    super();

    this.$http.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          this.logout();
        }

        return Promise.reject(error);
      }
    );
  }

  public isLoggedIn(): boolean {
    return Authenticator.getInstance().isLoggedIn();
  }

  public logout(): void {
    Authenticator.getInstance().logout();
    this.$router.push("/login");
  }
}
