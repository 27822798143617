





























import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";
import Contract from "@/ValueObjects/Contract/Contract";
import DatePickerInput from "@/components/DatePickerInput.vue";

@Component({
  components: { DatePickerInput },
  props: {
    contract: {
      type: Contract,
      required: true
    },
    vOn: {
      type: Object
    },
    vBtnProps: {
      type: Object
    },
    vIconProps: {
      type: Object
    }
  }
})
export default class CancelContractButton extends Vue {
  isDialogOpen: boolean = false;
  isSendingRequest: boolean = false;
  date: Date = new Date();
  rule = (v: any) => !(v instanceof Date) || "End date is required";

  public cancelContract(): void {
    if (this.isSendingRequest) {
      return;
    }

    this.isSendingRequest = true;

    this.$http
      .put(
        `/api/contract/${this.$props.contract.id.id}/cancel`,
        {
          end: {
            date: DatePickerInput.convertDateToISO8601(this.date)
          }
        },
        {
          headers: {
            Authorization: "Bearer " + Authenticator.getInstance().token
          }
        }
      )
      .then(() => {
        this.$emit("contractCancelled");
        this.isDialogOpen = false;
      })
      .finally(() => {
        this.isSendingRequest = false;
      });
  }
}
