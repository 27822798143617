enum Currency {
  EUR = "EUR"
}

export default class Money {
  private readonly _amount: number;
  private readonly _currency: Currency;

  constructor(amount: number | string, currency: Currency) {
    this._amount = Number(amount);
    this._currency = currency;
  }

  static EUR(amount: number): Money {
    return new Money(amount, Currency.EUR);
  }

  static fromResponse({
    amount,
    currencyCode
  }: {
    amount: string;
    currencyCode: string;
  }): Money {
    // @ts-ignore
    return new Money(amount, <Currency>Currency[currencyCode]);
  }

  get amount(): number {
    return this._amount;
  }

  get currency(): Currency {
    return this._currency;
  }

  public add(value: Money): Money {
    if (value.currency !== this.currency) {
      throw new Error(`Unable to add ${value.currency} to ${this.currency}`);
    }

    return new Money(this.amount + value.amount, this.currency);
  }
}
