import Money from "@/ValueObjects/Shared/Money";

export enum PaymentPlans {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  SEMI_ANNUALLY = "semi-annually",
  ANNUALLY = "annually"
}

export default class PaymentPlan {
  private readonly _plan: PaymentPlans;
  private readonly _amount: Money;
  private readonly _effectiveFrom: Date;

  constructor(plan: PaymentPlans, amount: Money, effectiveFrom: Date) {
    this._plan = plan;
    this._amount = amount;
    this._effectiveFrom = effectiveFrom;
  }

  static fromResponse(data: any): PaymentPlan {
    return new PaymentPlan(
      <PaymentPlans>PaymentPlans[data.plan.toUpperCase()],
      Money.fromResponse(data.amount),
      new Date(Date.parse(data.effectiveFrom.date))
    );
  }

  get plan(): PaymentPlans {
    return this._plan;
  }

  get amount(): Money {
    return this._amount;
  }

  get effectiveFrom(): Date {
    return this._effectiveFrom;
  }
}
