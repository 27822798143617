

































import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";
import Month from "@/ValueObjects/Shared/Month";
import { default as ReportDTO } from "@/ValueObjects/Report/Report";
import MonthReport from "@/ValueObjects/Report/MonthReport";
import ReportChart from "@/components/ReportChart.vue";

@Component({
  components: {
    ReportChart
  }
})
export default class Report extends Vue {
  isLoading: boolean = false;
  chartData: object = {};
  chartOptions: object = {
    responsive: true
  };
  report: ReportDTO | null = null;
  income: any;
  isInitialized: boolean = false;

  constructor() {
    super();

    this.load();
  }

  public prev(): void {
    this.load(this.report !== null ? this.report.year - 1 : null);
  }

  public next(): void {
    this.load(this.report !== null ? this.report.year + 1 : null);
  }

  private load(year: number | null = null): void {
    if (this.isLoading) {
      return;
    }

    if (year === null) {
      year = new Date().getFullYear();
    }

    this.isLoading = true;

    this.$http
      .get("/api/report/year", {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token
        },
        params: {
          year
        }
      })
      .then(({ data }: { data: any }) => {
        this.isInitialized = true;

        data.monthReports = Object.values(data.monthReports);
        this.report = ReportDTO.fromResponse(data);
        this.income = data.income;

        this.chartData = this.generateChartData(this.report);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private generateChartData(report: ReportDTO): object {
    let chartData: any = {
      labels: [],
      datasets: []
    };

    for (let i = 1; i <= 12; i++) {
      chartData.labels.push(new Month(i).value);
    }

    chartData.datasets.push({
      label: "Income",
      borderColor: "#78D663",
      fill: false,
      data: this.income[report.year]
    });

    chartData.datasets.push({
      label: "Expense",
      borderColor: "#C23B22",
      fill: false,
      data: report.monthReports.map((monthReport: MonthReport) => {
        return monthReport.totalAmount.amount / 100;
      })
    });

    chartData.datasets.push({
      label: "Average expense",
      borderColor: "#FFB347",
      fill: false,
      data: Array(12).fill(report.totalExpenses.amount / 100 / 12)
    });

    return chartData;
  }
}
