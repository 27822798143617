<script>
import { Line, mixins } from "vue-chartjs";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  mixins: [Line, mixins.reactiveProp],
  props: ["options"]
})
export default class ReportChart extends Vue {
  mounted() {
    this.renderChart(this.$props.chartData, this.$props.options);
  }
}
</script>
