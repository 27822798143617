import Money from "@/ValueObjects/Shared/Money";

export default (value: Money) => {
  let formatter = new Intl.NumberFormat(["en-US", "de-DE"], {
    style: "currency",
    currency: value.currency.toString(),
    minimumFractionDigits: 2
  });

  return formatter.format(value.amount / 100);
};
