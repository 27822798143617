var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[(_vm.contract !== null)?[_c('v-card',[_c('v-fab-transition',[_c('DeleteContractButton',{attrs:{"contract":_vm.contract,"v-btn-props":{
                absolute: true,
                top: true,
                right: true,
                dark: true,
                fab: true,
                color: 'red darken-4'
              }},on:{"contractDeleted":_vm.contractDeleted}})],1),_c('v-card-title',[_vm._v(_vm._s(_vm.contract.title))]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.contract.period.begin))+" - "),(_vm.contract.period.end !== null)?[_vm._v(" "+_vm._s(_vm._f("date")(_vm.contract.period.end))+" ")]:[_vm._v("now")]],2),_c('v-card-text',[_c('h3',[_vm._v("Payment plans")]),_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Activation Date")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Interval")])])]),_c('tbody',_vm._l((_vm.contract.paymentPlans),function(paymentPlan,index){return _c('tr',{key:("contract-paymentPlan-" + index)},[_c('td',[_vm._v(_vm._s(_vm._f("date")(paymentPlan.effectiveFrom)))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(paymentPlan.amount)))]),_c('td',[_vm._v(_vm._s(paymentPlan.plan))])])}),0)])])],1),_c('h2',[_vm._v("Payments")]),_vm._l((_vm.contract.payments),function(payment){return _c('v-card',{key:("contract-payment-" + (payment.id)),staticStyle:{"margin-bottom":"1rem"}},[_c('v-card-text',[_vm._v(" "+_vm._s(payment.dueDate.date)+": "+_vm._s(_vm._f("currency")(payment.amount))+" ")])],1)}),_c('h2',[_vm._v("History")]),_vm._l((_vm.contract.evenaments),function(event){return _c('v-card',{key:("contract-event-" + (event.id)),staticStyle:{"margin-bottom":"1rem"}},[_c('v-card-title',[_vm._v(_vm._s(event.eventName))]),_c('v-card-subtitle',[_vm._v(_vm._s(event.occurredAt))])],1)})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }