export default class ContractPeriod {
  private readonly _begin: Date;
  private readonly _end: Date | null;

  constructor(begin: Date, end: Date | null) {
    this._begin = begin;
    this._end = end;
  }

  static fromResponse(data: any): ContractPeriod {
    return new ContractPeriod(
      new Date(Date.parse(data.begin.date)),
      data.end ? new Date(Date.parse(data.end.date)) : null
    );
  }

  get begin(): Date {
    return this._begin;
  }

  get end(): Date | null {
    return this._end;
  }
}
