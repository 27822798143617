import Contract from "@/ValueObjects/Contract/Contract";
import MonthReport from "@/ValueObjects/Report/MonthReport";
import Money from "@/ValueObjects/Shared/Money";

export default class Report {
  private readonly _contracts: Contract[];
  private readonly _monthReports: MonthReport[];
  private readonly _year: number;
  private readonly _totalExpenses: Money;
  private readonly _recommendedIncome: Money;
  private readonly _recommendedBalance: Money;

  constructor(
    contracts: Contract[],
    monthReports: MonthReport[],
    year: number,
    recommendedIncome: Money,
    recommendedBalance: Money
  ) {
    this._contracts = contracts;
    this._monthReports = monthReports;
    this._year = year;
    this._recommendedIncome = recommendedIncome;
    this._recommendedBalance = recommendedBalance;
    this._totalExpenses = monthReports.reduce(
      (amount: Money, monthReport: MonthReport) => {
        return amount.add(monthReport.totalAmount);
      },
      Money.EUR(0)
    );
  }

  public static fromResponse(data: any): Report {
    return new Report(
      data.contracts.map((contractData: any) => {
        return Contract.fromResponse(contractData);
      }),
      data.monthReports.map((monthReportData: any) => {
        return MonthReport.fromResponse(monthReportData);
      }),
      data.year,
      Money.fromResponse(data.recommendedMonthlyIncome),
      Money.fromResponse(data.startBalanceForRecommendedMonthlyIncome),
    );
  }

  get contracts(): Contract[] {
    return this._contracts;
  }

  get monthReports(): MonthReport[] {
    return this._monthReports;
  }

  get year(): number {
    return this._year;
  }

  get totalExpenses(): Money {
    return this._totalExpenses;
  }

  get recommendedIncome(): Money {
    return this._recommendedIncome;
  }

  get recommendedBalance(): Money {
    return this._recommendedBalance;
  }
}
