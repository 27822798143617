import Axios from "axios";
import { AuthService, LoginResponse } from "@/api/AuthService";

export default class Authenticator implements AuthService {
  private static readonly LOCAL_STORAGE_KEY_TOKEN: string = "token";
  private static instance: Authenticator | null = null;
  private _redirectUrl: string | null;

  private constructor() {
    this._redirectUrl = null;
  }

  public static getInstance(): Authenticator {
    if (Authenticator.instance === null) {
      Authenticator.instance = new Authenticator();
    }

    return Authenticator.instance;
  }

  get token(): string | null {
    return localStorage.getItem(Authenticator.LOCAL_STORAGE_KEY_TOKEN);
  }

  public login(email: string, password: string): Promise<void | LoginResponse> {
    return Axios.post("/api/login_check", {
      username: email,
      password: password
    }).then(({ data: { token } }) => {
      localStorage.setItem(Authenticator.LOCAL_STORAGE_KEY_TOKEN, token);
    });
  }

  public isLoggedIn(): boolean {
    return this.token !== null;
  }

  logout(): void {
    localStorage.removeItem(Authenticator.LOCAL_STORAGE_KEY_TOKEN);
  }

  get redirectUrl(): string {
    return this._redirectUrl ? this._redirectUrl : "/";
  }

  public setRedirectUrl(value: string | null): void {
    this._redirectUrl = value;
  }
}
