




























import Vue from "vue";
import Authenticator from "@/api/Authenticator";
import Component from "vue-class-component";
import Contract from "@/ValueObjects/Contract/Contract";

@Component({
  props: {
    contract: {
      type: Contract,
      required: true
    },
    vBtnProps: {
      type: Object
    },
    vIconProps: {
      type: Object
    }
  }
})
export default class DeleteContractButton extends Vue {
  isDialogOpen: boolean = false;
  isDeleting: boolean = false;

  public deleteContract(): void {
    if (this.isDeleting) {
      return;
    }

    this.isDeleting = true;

    this.$http
      .delete(`/api/contract/${this.$props.contract.id.id}`, {
        headers: {
          Authorization: "Bearer " + Authenticator.getInstance().token
        }
      })
      .then(() => {
        this.$emit("contractDeleted");
      })
      .finally(() => {
        this.isDeleting = false;
      });
  }
}
